// extracted by mini-css-extract-plugin
export var aimhigher = "about-module--aimhigher--0a7b4";
export var background = "about-module--background--fffe9";
export var blob = "about-module--blob--00c57";
export var dots = "about-module--dots--561f5";
export var founder = "about-module--founder--8d5e3";
export var graphic = "about-module--graphic--ab642";
export var header = "about-module--header--92ed9 header-module--banner--fcf6f";
export var heart = "about-module--heart--93fe2";
export var image_blob = "about-module--image_blob--11f81";
export var leaf = "about-module--leaf--076e6";
export var logos = "about-module--logos--2cd1b";
export var paw = "about-module--paw--93a82";
export var section = "about-module--section--38487";
export var squiggle = "about-module--squiggle--50547";
export var underlay = "about-module--underlay--2aeb8";